@import "./style/vars";
@import "./style/atomic";
@import "./style/hero";
@import "./style/links";
@import "./style/card";
@import "./style/header";
@import "./style/footer";
@import "./style/copyright";
@import "./style/carousel";
@import "./style/logos";
@import "./style/picker";

html {
  scroll-behavior: smooth;
}

#app {
  font-family: 'Spartan', sans-serif;
  color: white;
  // background-image: url("./textured-paper.png");
  position: relative;
  width: 100%;
  overflow: hidden;

  .app-border {
    position: absolute;
    border: .25rem solid rgba(255, 255, 255, .4);
    height: 95%;
    top: 1%;
    left: 1rem;
    right: 1rem;
  }
}
