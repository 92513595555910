footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
  background: linear-gradient(#00000000, #ffffff);
  color: black;
  text-align: center;
}
