$carousel-height: 40rem;

.carousel-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100vw;
  max-height: $carousel-height;

  .carousel-tabs {
    position: absolute;
    bottom: 5rem;
    background:rgba(0, 0, 0, 0.7);
    padding: .25rem;
    border-radius: 10px;
    z-index: 10;
    border: rgba(255, 255, 255, 0.5) 1px solid;

    .carousel-tab {
      padding: 0 .25rem;
      circle {
        fill: white;
      }

      &.active {
        circle {
          fill: #ffef40;
        }
      }
    }
  }

  .viewport-wrapper {
    overflow: hidden;
    width: 100%;
  }

  .carousel-viewport {
    height: 100%;
    position: relative;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    touch-action: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &.no-scroll-animation {
      scroll-behavior: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slide {
    white-space: normal;
    position: relative;
    vertical-align: top;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
    width: 100%;
  }

  .carousel-nav {
    position: absolute;
    bottom: 15%;
    height: 65%;
    width: 45%;
    outline: none;
    z-index: 15;

    @media screen and (min-width: 600px) {
      top: 45%;
      height: auto;
      width: auto;
      bottom: auto;
    }

    .nav-icon {
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border-radius: 200px;
      background: white;
      color: black;
      position: absolute;
      top: 45%;

      &.icon-right {
        right: 0;
      }

      &.icon-left {
        left: 0;
      }

      @media screen and (min-width: 600px) {
        position: relative;
      }

      @media screen and (min-width: 1080px) {
        svg {
          font-size: 4rem;
        }
      }

      @media screen and (min-width: 1440px) {
        svg {
          font-size: 5rem;
        }
      }

      &:hover {
        color: white;
        cursor: pointer;
        background: black;
      }
    }


    &.nav-left {
      left: 1rem;

      @media screen and (min-width: 600px) {
        left: 5rem;
      }

      @media screen and (min-width: 1080px) {
        left: 10rem;
      }

      @media screen and (min-width: 1440px) {
        left: 15rem;
      }
    }

    &.nav-right {
      right: 1rem;

      @media screen and (min-width: 600px) {
        right: 5rem;
      }

      @media screen and (min-width: 1080px) {
        right: 10rem;
      }

      @media screen and (min-width: 1440px) {
        right: 15rem;
      }
    }
  }
}
