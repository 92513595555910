$items-per-ring: 10;

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate($rot * -1deg);
      }

      &:hover {
        & > * {
          transform: scale(1.5);
        }
      }

      $rot: $rot + $angle;
    }
  }
}

.logo-component {
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s ease;
  width: 100%;
  height: 100%;
  box-shadow: 2px 2px 10px black;
  cursor: pointer;

  &:hover {
    transform: scale(1.25);
  }

  a {
    display: flex;
    height: 100%;
    align-items: center;
  }

  img {
    max-width: 100%;
    height: 100%;
    max-height: 4rem;
    border-radius: 50%;
    object-fit: contain;
  }

  &.img-resize {
    img {
      max-height: 3rem;
      border-radius: 0;
    }
  }
}

.logos-component {
  .logo-wrapper {
    background: #00000080;
    border-radius: 50%;
    margin: .5rem;
  }

  &.tech {
    top: 3rem;

    &.tech1 {
      @include on-circle($item-count: $items-per-ring, $circle-size: 20rem, $item-size: 5.5rem);

      // @media screen and (min-width: 760px) {
      //   @include on-circle($item-count: 10, $circle-size: 22rem, $item-size: 5.5rem);
      // }
    }

    &.tech2 {
      @include on-circle($item-count: $items-per-ring, $circle-size: 10rem, $item-size: 3.5rem);
      top: -12rem;
      left: 25%;

      // @media screen and (min-width: 760px) {
      //   @include on-circle($item-count: 10, $circle-size: 11rem, $item-size: 3.5rem);
      //   top: -13.5rem;
      // }
    }
  }

  &.places {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 2rem;

    @media screen and (min-width: 760px) {
      // flex-direction: column;
      padding: 0;
      max-width: 20rem;
    }

    & > .logo-wrapper {
      width: 7rem;
      height: 7rem;
    }
    // @include on-circle($item-count: 5, $circle-size: 20rem, $item-size: 5rem);
  }
}
