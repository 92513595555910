.hero-component {
  &:not(.app-hero) {
    display: grid;
    grid-template: $grid-template-mobile / $grid-template-mobile;
    justify-content: center;
    align-content: center;
    position: relative;
    width: 100%;
    min-height: 50rem;

    .hero-title {
      border: 10px solid white;
      padding: 1rem;
      margin: 0;
    }

    @media screen and (min-width: 600px) {
      grid-template: $grid-template-desktop / $grid-template-desktop;
    }

    & > * {
      align-self: center;
      justify-self: center;

      &:not(.background-video) {
        z-index: 2;
      }
    }

    // & > .carousel-component.grid-bottom {
    //   @media screen and (min-width: 600px) {
    //     margin-top: -18rem;
    //   }
    // }

    &.home {
      height: 100vh;
      min-height: auto;
    }

    .home-content {
      color: white;
      text-align: center;

      @media screen and (min-width: 600px) {
        color: black;
        text-align: left;
      }
    }

    .about-me-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .headshot {
        border-radius: 200px;
        width: 50%;
      }

      .about-me-text {
        padding: 2rem;
        text-align: center;

        .title-wrapper {
          display: flex;
          justify-content: center;

          @media screen and (min-width: 760px) {
            justify-content: flex-end;
          }
        }
      }

      @media screen and (min-width: 600px) {
        flex-direction: row;
        min-width: 50rem;

        img {
          width: 75%;
        }

        .about-me-text {
          text-align: end;
          margin-right: 5rem;
        }
      }
    }

    &.projects-hero {
      // margin-bottom: 20rem;

      // @media screen and (min-width: 600px) {
      //   margin-bottom: 30rem;
      // }
      // @media screen and (min-width: 760px) {
      //   margin-bottom: 40rem;
      // }
      // @media screen and (min-width: 1080px) {
      //   margin-bottom: 0;
      // }

      .hero-title {
        align-self: auto;
      }

      .carousel-component {
        @media screen and (min-width: 600px) {
          margin-top: 10rem;
        }

        @media screen and (min-width: 760px) {
          margin-top: 5rem;
        }

        @media screen and (min-width: 960px) {
          margin-top: 10rem;
        }
      }
    }

    &.misc-hero {
      display: flex;
      flex-direction: column;
      // height: 100rem;

      @media screen and (min-width: 1080px) {
        display: grid;
        margin-bottom: 0;
        // height: 50rem;
      }

      h1, .hero-title {
        text-align: center;
      }

      .arrow-down {
        text-align: center;
        margin-bottom: 1rem;
        animation: glow 2s ease infinite;
      }

      .misc-section {
        margin-top: 2rem;

        h1 {
          text-decoration: underline;
        }
      }

      .resume {
        display: flex;
        flex-direction: column;

        .resume-download {
          padding: 1.5rem;
          border-radius: 10px;
          text-align: center;
          font-weight: bold;
          color: inherit;
          box-shadow: 2rem 2rem 2rem black;
          text-decoration: none;
          background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
          background-size: 400% 400%;
          animation: gradient 5s ease infinite;
          transition: transform 1s ease;

          &:hover {
            transform: scale(1.15);
          }
        }
      }

      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }

      @keyframes glow {
        0% {
          text-shadow: 0 white;
        }
        50% {
          text-shadow: 2px 0 5px white, -2px 0 5px white;
        }
        100% {
          text-shadow: 0 white;
        }
      }

      .tech-stack {
        @media screen and (min-width: 1080px) {
          margin-top: 9rem;
        }
      }
    }
  }

  .background-video {
    position: fixed;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
  }
}
