header {
  position: fixed;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-evenly;

  @media screen and (min-width: 660px) {
    justify-content: flex-end;
  }

  a {
    padding: 1rem;
    text-decoration: none;
    color: inherit;
    transition: color .25s ease, text-shadow .25s ease;

    &:hover {
      color: white !important;
      text-shadow: 1px 1px 1px black;
    }

    &.desktop-only {
      display: none;

      @media screen and (min-width: 600px) {
        display: inherit;
      }
    }
  }
}
