$card-border-radius: 20px;
$card-mobile-height: 45rem;
$card-mobile-width: 18rem;
$card-tablet-width: 36rem;
$card-desktop-width: 35rem;
$card-desktop-height: 35rem;
$card-ultrawide-width: 45rem;
$card-ultrawide-height: 45rem;
$card-container-height: 75%;

.card-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: contain;
  align-items: center;
  background-repeat: no-repeat;
  width: $card-mobile-width;
  height: $card-mobile-height;

  @media screen and (min-width: 760px) {
    width: $card-desktop-width;
    height: $card-desktop-height;
  }

  @media screen and (min-width: 960px) {
    width: $card-ultrawide-width;
    height: $card-ultrawide-height;
  }

  .card-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin: 0 0 5rem;
    top: 2rem;
    object-fit: contain;
    border-radius: $card-border-radius;
    border: white 1px solid;
    width: $card-mobile-width;
    height: $card-container-height;
    background-size: cover;
    background-repeat: no-repeat;
    background-color:rgba(0, 0, 0, 0.7);
    box-shadow: 2rem 2rem 2rem black;

    @media screen and (min-width: 760px) {
      flex-direction: row;
      width: $card-desktop-width;
    }

    @media screen and (min-width: 960px) {
      width: $card-ultrawide-width;
    }

    .card-image {
      // position: absolute;
      width: 100%;
      height: 100%;
      flex: 2;

      @media screen and (min-width: 760px) {
        border-radius: $card-border-radius 0 0 $card-border-radius;
      }

      .card-image-filter {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: $card-border-radius;
        z-index: 10;
        background:linear-gradient(black, #0000, #0000, #000000b3);

        @media screen and (min-width: 760px) {
          background:linear-gradient(90deg, #0000, #0000000f, #000000b3);
        }
      }

      img {
        object-fit: cover;
        margin-top: 6rem;
        height: 20rem;

        @media screen and (min-width: 760px) {
          margin-top: 0;
          height: 100%;
        }
      }
    }

    .card-text-container {
      z-index: 10;
      flex: 1;
      border-radius: $card-border-radius;

      @media screen and (min-width: 760px) {
        background-color: white;
        opacity: .95;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        border-radius: 0 $card-border-radius $card-border-radius 0;
        box-shadow: -2px 0 10px white;
      }
    }

    .card-text {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      z-index: 10;
      color: black;
      background-color: white;
      min-height: 3rem;
      justify-content: center;
      opacity: .95;


      @media screen and (min-width: 760px) {
        position: relative;
        bottom: 0;
        min-height: 7rem;
      }

      &.title {
        top: 0;
        right: 0;
        border-radius: $card-border-radius $card-border-radius 0 0;
        box-shadow: 0 2px 10px white;

        @media screen and (min-width: 760px) {
          bottom: auto;
          border-radius: 0 $card-border-radius 0 0;
          box-shadow: none;
        }

        h1 {
          margin-bottom: 0;
        }
      }

      &.description {
        right: 0;
        bottom: 0;
        font-size: .75rem;
        line-height: 1.25rem;
        display: flex;
        box-shadow: 0 -2px 10px white;
        min-height: 7rem;
        border-radius: 0 0 $card-border-radius $card-border-radius;

        p {
          padding-bottom: 3rem;

          @media screen and (min-width: 760px) {
            padding-bottom: 0;
          }
        }

        @media screen and (min-width: 760px) {
          min-height: 3rem;
          border-radius: 0 0 $card-border-radius 0;
          box-shadow: none;
          bottom: 0;
        }
      }

      h1, p {
        text-align: center;
        padding: 0 1rem;
      }

      .card-links {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 1rem;

        span {
          text-align: center;
        }
      }
    }
  }
}
