.links-component {
  span {
    a {
      color: $icon-color-secondary;
      opacity: 0.6;
      text-decoration: none;
      padding-right: 0.75rem;

      &:hover {
        opacity: 1;
      }
    }

    &:not(.text-link):hover {
      svg {
        transform: scale(1.5);
        transition: transform 0.25s;
      }
    }
  }
}
