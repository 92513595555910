.picker-component {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 40rem;

  @media screen and (min-width: 760px) {
    margin-top: 10rem;
  }

  .card-component .card-text-container {
    margin-bottom: 4rem;

    @media screen and (min-width: 760px) {
      border-radius: 0 20px 0 0;
      margin-bottom: 3.5rem;
    }

    @media screen and (min-width: 960px) {
      margin-bottom: 3rem;
    }
  }

  .thumbnails {
    z-index: 10;
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    position: absolute;
    bottom: 8rem;
    background: linear-gradient(rgba(255, 255, 255, 0), rgb(41, 41, 41));
    border-radius: 0 0 20px 20px;

    @media screen and (min-width: 760px) {
      background: linear-gradient(rgba(255, 255, 255, 0), rgb(55, 55, 55), black);
      height: 6.5rem;
      bottom: 6.7rem;
    }

    @media screen and (min-width: 960px) {
      height: 8rem;
      bottom: 7.95rem;
    }

    .thumbnail {
      width: 2rem;
      height: 2rem;
      background-size: 3rem 3rem;
      cursor: pointer;
      transition: transform 0.25s ease-in-out, opacity 0.25s;
      display: none;
      border: 1px solid rgb(162, 162, 162);
      border-radius: 100px;
      opacity: 0.75;
      margin-bottom: 0.5rem;

      &.active {
        border: 1px solid white;
        opacity: 1;
      }

      @media screen and (min-width: 760px) {
        display: block;
        width: 2rem;
        height: 2rem;
      }

      @media screen and (min-width: 960px) {
        width: 3rem;
        height: 3rem;
      }

      // .thumbnail-title {
      //   display: none;
      // }

      &:hover {
        opacity: 1;
        transform: scale(1.5);
        z-index: 50;

        // .thumbnail-title {
        //   display: block;
        //   text-align: center;
        //   text-shadow: 2px 2px 2px black;
        // }
      }

      // &:first-child {
      //   border-radius: 0 0 0 19px;
      // }

      // &:last-child {
      //   border-radius: 0 0 19px 0;
      // }
    }
  }

  .thumbnail-navigation {
    position: absolute;
    display: flex;
    height: 0;
    bottom: 11.5rem;
    text-align: center;
    align-items: center;
    text-shadow: 2px 2px 2px black;
    right: 5%;
    left: 5%;
    width: 90%;
    justify-content: space-between;
    z-index: 30;

    @media screen and (min-width: 760px) {
      width: 120%;
      left: -10%;
      right: -10%;
      bottom: 21rem;
    }

    @media screen and (min-width: 960px) {
      width: 130%;
      left: -15%;
      right: -15%;
      bottom: 25rem;

      .nav-icon {
        font-size: 5em;
      }
    }

    .nav-icon {
      position: absolute;
      top: 30%;
      cursor: pointer;
      transition: transform 0.25s ease-in-out;

      &.nav-left {
        left: 0;
      }
      &.nav-right {
        right: 0;
      }

      &:hover {
        transform: scale(1.25);
      }
    }
  }
  .thumbnail-title-wrapper {
    position: absolute;
    bottom: 4rem;
    width: 100%;

    @media screen and (min-width: 960px) {
      bottom: 5rem;
    }

    .thumbnail-title {
      text-align: center;
      margin: 0;
    }
  }
}
